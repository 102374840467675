import React, {useRef} from 'react';
import rootStore from "../../store/RootStore";
import styles from "./CategorySmallSlider.module.scss"
import CategorySmallItem from "./CategorySmallItem/CategorySmallItem";
import {observer} from "mobx-react";

const CategorySmallSlider = ({handleClickCategory}) => {
    const sliderContainerRef = useRef(null);
    const categories = rootStore.categoriesStore.categoriesList;

    return (
        <div className={styles.mainConteiner}>
            <div className={styles.mainBlock}>
                <div className={styles.sliderBar}>
                    <div className={styles.sliderBarBlock}>
                        <div className={styles.sliderConteiner} ref={sliderContainerRef}>
                            {
                                categories.map((cat) => (
                                    <CategorySmallItem handleClickCategory={handleClickCategory} category={cat} key={cat.CategoryID} />
                                ))
                            }
                            <div className={styles.fakeElem}></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default observer(CategorySmallSlider);