import React from 'react';
import UpBody from "../../components/MainBody/UpBody/UpBody";
import CountryMainBody from "../../components/MainBody/CountryMainBody/CountryMainBody";
import VideoPlayerBlock from "../../components/MainBody/VideoPlayerBlock/VideoPlayerBlock";
import TitleTextBlock from "../../components/MainBody/TitleTextBlock/TitleTextBlock";
import AppBanner from "../../components/MainBody/AppBanner/AppBanner";
import DownBanner from "../../components/MainBody/DownBanner/DownBanner";
import FakeBlock from "../../components/MainBody/FakeBlock/FakeBlock";

const MainBody = () => {
    return (
        <>
            <UpBody />
            <CountryMainBody/>
            <VideoPlayerBlock/>
            <TitleTextBlock/>
            <AppBanner/>
            <DownBanner/>
            <FakeBlock/>
        </>
    );
};

export default MainBody;