import React, {useEffect, useState} from 'react';
import styles from './StoresBody.module.scss'
import RestoranHeader from "../../components/Restoran/RestoranHeader/RestoranHeader";
import StoreBody from "../../components/Stores/StoreBody/StoreBody";
import {useParams} from "react-router-dom";
import {getShopData} from "../../api/getContentsApi/getShopData";
import {observer} from "mobx-react";
import rootStore from "../../store/RootStore";

const MainPage = () => {
    const { id } = useParams()

    useEffect(() => {
       getShopData(id)
      .then(response => rootStore.shopStore.addShopData(response.data))
      .catch(error => console.log(error));
        window.scrollTo(0, 0);
    }, [id]);
    return (
        <div className={styles.mainPage}>
            <RestoranHeader />
            <StoreBody/>
        </div>
    );
};

export default observer(MainPage);