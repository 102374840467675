import React, {useEffect, useState} from 'react';
import styles from './Header.module.scss'
import logoImg from './static/logo.png'
import Button from "./Buttons/Button";
import PersonalBtn from "./PersonalBtn/PersonalBtn";
import LocationBlock from "./LocationBlock/LocationBlock";
import SearchField from "./SearchField/SearchField";
import BasketButton from "./BasketButton/BasketButton";
import Basket from "../Basket/Basket";
import {Drawer} from "@mui/material";
import ModalLogin from "../ModalLogin/ModalLogin";
import ModalLocation from "../ModalLocation/ModalLocation";
import {observer} from "mobx-react";
import rootStore from "../../store/RootStore";
import IconBack from "../Icons/IconBack";
import {Link, useMatch, useNavigate} from "react-router-dom";
import FilterIcons from "../../pages/DiscoveryWrapper/static/FilterIcons";
import ModalFilters from "../ModalFilters/ModalFilters";
import {makeStyles} from "@mui/styles";
import {useTheme} from "../../helpers/ThemeContext";

const Header = () => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [isTransparent, setIsTransparent] = useState(true);

    const { getIsBasketOpen: basketOpen, setBasketOpen } = rootStore.cartOrderStore;
    const { setIsOpenPersonal } = rootStore.systemStore;

    const currentIsHome= window.location.pathname !== '/';
    const navigate = useNavigate();

    const isRestoran= window.location.pathname.includes('/stores/');
    const isMain = window.location.pathname === '' || window.location.pathname === '/';
    const isDiscovery = window.location.pathname.includes('/discovery');
    const isProfile = window.location.pathname.includes('/me');
    const isSupport = window.location.pathname.includes('/support-panel')
    const isSearchPage = window.location.pathname.includes('/search-store')
    const isMainDiscovery = window.location.pathname ===  ('/discovery');
    const isCheckout = window.location.pathname.includes('/checkout-mobile');
    const isStore = window.location.pathname.includes('/stores/');
    const isCurier = window.location.pathname.includes('/curier-panel');
    const isModerator = window.location.pathname.includes('/moderator');
    const isFixedRest = window.location.pathname === '/discovery/restaurants' || window.location.pathname === '/discovery/stores'
    const isOwner = window.location.pathname.includes("/owner")
    const isSupportChat = window.location.pathname.includes("/user-support-panel")

    const isAuth = rootStore.userStore.isAuth;
    const {themeMode} = useTheme()
    const useStyles = makeStyles({
        paperBackground: {
            '& .MuiDrawer-paper ': {
                backgroundColor: `${themeMode === 'dark' ? '#1c1c1c' : 'white'}`,
                backgroundImage: "none",
            },
        },
    });

    useEffect(() => {
        if (isCheckout) {
            setBasketOpen(isCheckout);
        }
    }, [isCheckout])

    const classes = useStyles();
    const toggleDrawer = (newOpen) => () => {
        setBasketOpen(newOpen);
        setIsOpenPersonal(false)
    };

    const closeDrawer = () => {
        setBasketOpen(false);
    };
    const authUser = rootStore.userStore.user.authorized

    useEffect(() => {

        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        function handleScrollHeader() {
            const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
            if (scrollTop === 0) {
                setIsTransparent(true);
            } else {
                setIsTransparent(false);
            }
        }

        window.addEventListener('scroll', handleScrollHeader);
    }, []);

    return (
        <>
            <ModalLogin />
            {currentIsHome && windowWidth < 830 && !isDiscovery && !isCheckout && !isStore && !isCurier && !isModerator && !isOwner && isSupportChat && <div style={{
                "position": "fixed",
                "width": "32px",
                "height": "32px",
                "zIndex": 997,
                "top": "5.8%",
                "left": "3%",}}
                  onClick={() => navigate(-1)}><IconBack size={20} pure={true} fill={"#009de0"}/></div>}
            {
                !isRestoran && !isMain && !isProfile && !isSearchPage && !isCurier && !isModerator && !isOwner && !isSupport && !isSupportChat ? (<div className={styles.fantomBlockF}></div>) : ""
            }
            <div className={`${styles.containerHeader} ${!isTransparent && styles.shadowStyle} ${isRestoran || isFixedRest ? styles.fixedHeader : "" } ${isCheckout || isSearchPage || isCurier || isModerator || isOwner || isSupport || isSupportChat ? styles.kill : ""}`}>
                {
                    isDiscovery || isCheckout || isStore || isCurier || isSupport || isSupportChat ?
                        "" :
                        (
                            <header
                                className={`${styles.contentBlocks} ${isTransparent && isRestoran ? styles.backgoundFantom : ""}`}>
                                <div className={styles.mainBlock}>
                                    <div className={styles.leftContent}>
                                        <Link to="/discovery" className={styles.logoBlock}>
                                            <img
                                                src={logoImg}
                                                className={`${styles.logoConteiner} ${isTransparent && isRestoran ? styles.whiteLogo : ""}`}
                                                alt="">
                                            </img>
                                        </Link>
                                    </div>
                                    {isAuth && currentIsHome && windowWidth > 830? (
                                        <ModalLocation>
                                            <LocationBlock/>
                                        </ModalLocation>
                                    ) : ""}
                                </div>
                                {currentIsHome && windowWidth ? (
                                        <SearchField isUpRestHeader={isTransparent && isRestoran}/>) :
                                    <div className={styles.fakeBlock}></div>}
                                <div className={styles.rightBlock}>
                                    <div className={`${styles.contentRightBlocks} ${!isAuth ? styles.otstup : ""}`}>
                                        <div className={styles.conteinerBtn}>
                                            {authUser ? <PersonalBtn/> : (windowWidth > 1024 ? (
                                                    <>
                                                        <Button text={"Log in"}
                                                                isUpRestHeader={isTransparent && isRestoran}/>
                                                        <Button text={"Sign up"}
                                                                isUpRestHeader={isTransparent && isRestoran}/>
                                                    </>
                                                ) : (<PersonalBtn/>)
                                            )}

                                        </div>
                                        {/*//if autorize user TODO*/}
                                        {isAuth && <div onClick={toggleDrawer(true)}>
                                            <BasketButton/>
                                        </div>}
                                        <Drawer anchor={windowWidth > 830 ? "right" : "bottom"}
                                                open={basketOpen}
                                                className={classes.paperBackground}
                                                onClose={toggleDrawer(false)}>
                                            <Basket closeDrawer={closeDrawer}></Basket>
                                        </Drawer>
                                    </div>
                                </div>
                            </header>
                        )
                }
                {currentIsHome && (windowWidth <= 830 && !isProfile && !isCheckout) && ((!isRestoran) || isFixedRest) ? (<div style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "start",
                    marginTop: "10px",
                    marginLeft: "1rem",
                    marginBottom: "10px"
                }}>
                    {
                        !basketOpen && (
                            <ModalLocation>
                                <LocationBlock stateFix={isTransparent}/>
                            </ModalLocation>
                        )
                    }
                    {isDiscovery && !isSearchPage ?
                        (
                            <div className={`${styles.infoBlock} ${basketOpen ? styles.collapse : ""}`} >
                                <Drawer anchor={windowWidth > 830 ? "right" : "bottom"}
                                        open={basketOpen}
                                        className={classes.paperBackground}
                                        onClose={toggleDrawer(false)}>
                                    <Basket closeDrawer={closeDrawer}></Basket>
                                </Drawer>
                                {
                                    isMainDiscovery ?
                                        (
                                            !basketOpen && (
                                                <div onClick={toggleDrawer(true)}>
                                                    <BasketButton/>
                                                </div>
                                            )
                                        ) :
                                        (
                                            <>
                                                <ModalFilters>
                                                    <FilterIcons/>
                                                </ModalFilters>
                                                {/*<MapIcons/>*/}
                                            </>
                                        )
                                }
                            </div>
                        ) : ""}
                </div>) : ""}
            </div>
        </>
    );
};

export default observer(Header);