import React from 'react';
import styles from "./DiscoverySlider.module.scss"
import Slider from "react-slick";
import Slides from "./Slides/Slides";
import {observer} from "mobx-react";
import rootStore from "../../../store/RootStore";
import Box from "@mui/material/Box";
import {CircularProgress} from "@mui/material";
import {Link} from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./slider.scss";



const settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    speed: 1000,
    autoplaySpeed: 3000,
    slidesToShow: 2,
    slidesToScroll: 2,
    initialSlide: 0,
    arrows:false,
    responsive: [
        {
            breakpoint: 1024,
            autoplay: true,
            speed: 1000,
            autoplaySpeed: 3000,
            dots: true,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                initialSlide: 1,
                infinite: true,
                dots: true,
            }
        },
        {
            breakpoint: 641,
            autoplay: true,
            speed: 1000,
            autoplaySpeed: 3000,
            dots: true,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                initialSlide: 1,
                dots: true,
            }
        }
    ]
};


const DiscoverySlider = () => {
    // const shops = rootStore?.shopStore?.shopsList;
    const sliders = rootStore?.discoveryStore?.discoverySlidersList;
    if(!sliders || sliders?.length === 0){
        return (
            <Box sx={{display: "flex", justifyContent: "center", alignItems: "center", height: "50vh"}}>
                <CircularProgress/>
            </Box>
        )
    }
    return (
        <div className={styles.mainConteiner} >
            <Slider {...settings}>
                {
                    sliders.map(elem =>
                        (
                            <Link state={{ shopId: elem.id }} key={elem.id} >
                                <Slides data={elem}/>
                            </Link>
                        ))
                }
            </Slider>
        </div>
    );
}

export default observer(DiscoverySlider);