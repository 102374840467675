import React, { useEffect, useState } from 'react';

import { t } from "i18next";
import {observer} from 'mobx-react';

import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from '@mui/material';

import styles from './CreateProductModal.module.scss';

import rootStore from "../../../../store/RootStore";
import { editProduct } from "../../../../api/addContent/editProduct";
import { videoSizeLimit } from "./constants";
import { getProductData } from "../../../../api/addContent/getProductData";
import { getSuperUserShopCategoriesList } from "../../../../api/getContentsApi/getSuperUserShopCategoriesList";
import {getModeratorCategoryList} from "../../../../api/getContentsApi/getModeratorCategoryList";

const EditProductModal = observer(() => {
    const [isSending, setIsSending] = useState(false);
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const {setCurrentEditProductId, setIsOpenEditProduct, currentEditProductID} = rootStore.moderatorPanelStore;
    const [input1, setInput1] = useState('');
    const [input2, setInput2] = useState('');
    const [input3, setInput3] = useState('');
    const [input4, setInput4] = useState('');
    const [input5, setInput5] = useState('');
    const [description, setDescription] = useState('');
    const [file, setFile] = useState(null);
    const [video, setVideo] = useState(null);
    const [oldVideo, setOldVideo] = useState(null);
    const [productInfo, setProductInfo] = useState('');
    const [errors, setErrors] = useState({});
    const [oldImage, setOldImage] = useState('');
    const [categoryListData, setCategoryListData] = useState([]);
    const storedShopValue = localStorage.getItem('currentShop');
    const shopId = rootStore.superUserStore.currentShopId || JSON.parse(storedShopValue)?.ShopID;

    const handleClose = () => {
        setIsOpenEditProduct(false);
    };

    useEffect(() => {
        const fetchData = async () => {
            if (shopId && currentEditProductID) {
                try {
                    const categories = await getModeratorCategoryList();
                    setCategoryListData(categories || []);

                    const {data: productData} = await getProductData(currentEditProductID);
                    if (productData) {
                        setInput1(productData.ProductName);
                        setInput2(productData.CategoryID);
                        setInput3(productData.unit);
                        setInput4(productData.Price);
                        setInput5(productData.quantity);
                        setDescription(productData.Description);
                        setProductInfo(productData.Product_info);
                        setOldImage(`${process.env.REACT_APP_MEDIA_URL}${productData.product_images[0].ImageUrl}`);
                        if (productData.product_videos[0]?.VideoUrl) {
                            setOldVideo(`${process.env.REACT_APP_MEDIA_URL}${productData.product_videos[0].VideoUrl}`);
                        }

                    }
                } catch (error) {
                    console.error('An error occurred:', error);
                }
            }
        };

        fetchData();

        return () => {
            setCategoryListData([]);
            // Заменил setCurrentEditProduct на setCurrentEditProductId, т.к. падала ошибка, что
            // нет функции setCurrentEditProduct
            setCurrentEditProductId(null);
        };
    }, [shopId, currentEditProductID, setCurrentEditProductId]);

    const validateInputs = () => {
        const newErrors = {};
        if (!input1.trim()) newErrors.input1 = t("Product name is required");
        if (!input2) newErrors.input2 = t("Category is required");
        if (!input3.trim()) newErrors.input3 = t("Unit is required");
        if (!input4.trim() || isNaN(input4)) newErrors.input4 = t("Price must be a number");
        if (!input5.trim() || isNaN(input5)) newErrors.input5 = t("Quantity must be a number");
        if (!description.trim()) newErrors.description = t("Description is required");
        if (!productInfo.trim()) newErrors.productInfo = t("Product info is required");
        if (file && !['image/jpeg', 'image/png', 'image/gif'].includes(file.type)) {
            newErrors.file = t("File must be an image (jpeg, png, gif)");
        }
        if (file && file.size > videoSizeLimit) {
            newErrors.file = t("File size must not exceed 50MB");
        }
        if (video) {
            // TODO: Временное решение увеличения количества возможных форматов видео
            // if (!['video/mp4'].includes(video.type)) {
            //     newErrors.video = t("File must be a video (mp4)");
            // }
            if (video.size > videoSizeLimit) {
                newErrors.video = t("Video size must not exceed 50MB");
            }
        }
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        setFile(file);
    };
    const handleVideoChange = (event) => {
        setVideo(null);
        const video = event.target.files[0];
        setVideo(video);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!validateInputs() || !shopId) return;

        const productData = new FormData();
        productData.append('ProductName', input1);
        productData.append('CategoryID', input2);
        productData.append('unit', input3);
        productData.append('Price', parseFloat(input4));
        productData.append('quantity', input5);
        productData.append('Description', description);
        productData.append('Product_info', productInfo);
        if (file) {
            productData.append('ImageUrl', file);
            productData.append('AltText', file.name);
        }
        if (video) {
            productData.append('VideoUrl', video);
            productData.append('VideoAltText', video.name);
        }

        try {
            setIsSending(true);
            const response = await editProduct(productData, shopId, currentEditProductID);
            if (response.status === 200) {
                setShowSuccessModal(true);
                setTimeout(() => {
                    setShowSuccessModal(false);
                    handleClose();
                }, 2000);
            } else {
                console.error('Error sending data to the server');
            }
        } catch (error) {
            console.error('Network error:', error);
        } finally {
            setIsSending(false);
        }
    };

    return (
        <>
            <Dialog open={showSuccessModal} onClose={handleClose}>
                <DialogTitle>{t("Success")}</DialogTitle>
                <DialogContent>
                    <p>{t("Product updated successfully!")}</p>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>{t("Close")}</Button>
                </DialogActions>
            </Dialog>
            <>
                <div className={styles.block1}>
                    <div className={styles.block2}>
                        <div className={styles.block3}>
                            <div className={styles.block4}>
                                <div className={styles.block5} data-testid="backButton" onClick={handleClose}>
                                <span className={styles.block6}>
                                    <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg"><path
                                        d="M8.41421356,13 L13.7071068,18.2928932 L12.2928932,19.7071068 L4.58578644,12 L12.2928932,4.29289322 L13.7071068,5.70710678 L8.41421356,11 L20,11 L20,13 L8.41421356,13 Z"
                                        fill="#716E7A"></path></svg>
                                </span>
                                </div>
                                <div className={styles.block7}>
                                    <span className={styles.block8}>{t("Products")}</span>
                                    <div className={styles.block9}></div>
                                    <span className={styles.block0}>{t("Edit Product")}</span>
                                </div>
                            </div>
                        </div>
                        <form onSubmit={handleSubmit}>
                            <div className={styles.conteiner1}>
                                <div className={styles.conteiner2}>
                                    <div className={styles.conteiner3}>
                                        <div className={styles.conteiner4}>
                                            <div>
                                                <div className={styles.conteiner5}>
                                                    <label className={styles.conteiner6} htmlFor="file">
                                                        <input
                                                            id="file"
                                                            type="file"
                                                            className={styles.conteiner7}
                                                            onChange={handleFileChange}
                                                        />
                                                    </label>
                                                    <img className={styles.preview}
                                                         src={file ? URL.createObjectURL(file) : oldImage}
                                                         alt=""/>

                                                </div>
                                                {errors.file && <div className={styles.error}>{errors.file}</div>}
                                                <div className={styles.conteiner9}>
                                                    {t("A product with a photo is easier to find at the checkout")}
                                                </div>

                                            </div>
                                            <div>
                                                <div className={styles.conteiner5}>
                                                    <label className={styles.conteiner6} htmlFor="video">
                                                        <input
                                                            id="video"
                                                            type="file"
                                                            className={styles.conteiner7}
                                                            onChange={handleVideoChange}
                                                        />
                                                    </label>

                                                    {oldVideo ? (
                                                        <video className={styles.preview} controls>
                                                            <source src={oldVideo} type={oldVideo.type}/>
                                                            {t("Your browser does not support the video tag.")}
                                                        </video>
                                                    ) : video ? 
                                                    <video 
                                                        className={styles.preview}
                                                        controls
                                                    >
                                                        <source src={video} type={video.type}/>
                                                        {t("Your browser does not support the video tag.")}
                                                    </video> :
                                                    <img 
                                                        className={styles.conteiner8}
                                                        src={"https://cloud.sigma.ru/svg/restaurant.svg"}
                                                        alt=""
                                                    />
                                                }
                                                </div>
                                                {errors.video && <div className={styles.error}>{errors.video}</div>}
                                                <div className={styles.conteiner9}>
                                                    {t("A product with a video can attract more customers.")}
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <div className={styles.main1}>
                                                <div className={styles.main2}>
                                                    <div data-label="Product Name" data-value="" data-focus="false"
                                                         data-error="false" id="name"
                                                         className={`${styles.input1} ${styles.input}`}
                                                         data-status="false">
                                                        <label className={styles.main4} htmlFor="9075">
                                                            {t("Product name")}
                                                        </label>
                                                        <div className={styles.main5}>
                                                            <input
                                                                autoComplete="off"
                                                                data-testid="nameProduct"
                                                                type="text"
                                                                className={styles.main6}
                                                                placeholder={t("Enter product name or scan barcode")}
                                                                data-value=""
                                                                value={input1}
                                                                onChange={(e) => setInput1(e.target.value)}
                                                            />
                                                        </div>
                                                        {errors.input1 &&
                                                            <span className={styles.error}>{errors.input1}</span>}
                                                    </div>
                                                    <div data-label="Category" data-value="" data-focus="false"
                                                         data-error="false" id="name"
                                                         className={`${styles.input2} ${styles.input}`}
                                                         data-status="false">
                                                        <label className={styles.main4} htmlFor="9075">{t("Category")}</label>
                                                        <div className={styles.main5}>
                                                            <select
                                                                autoComplete="off"
                                                                data-testid="nameProduct"
                                                                className={styles.main6}
                                                                value={input2}
                                                                onChange={(e) => setInput2(e.target.value)}
                                                            >
                                                                <option value="">{t("Select category...")}</option>
                                                                {categoryListData.length && categoryListData.map((category) => (
                                                                    <option
                                                                        key={category.CategoryID}
                                                                        value={category.CategoryID}
                                                                    >
                                                                        {category.CategoryName}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                        {errors.input2 &&
                                                            <span className={styles.error}>{errors.input2}</span>}
                                                    </div>
                                                    <div data-label="Description" data-value="" data-focus="false"
                                                         data-error="false" id="name"
                                                         className={`${styles.input6} ${styles.input}`}
                                                         data-status="false">
                                                        <label className={styles.main4}
                                                               htmlFor="9075">
                                                            {t("Description")}
                                                        </label>
                                                        <div className={styles.main5}>
                                                            <input
                                                                autoComplete="off"
                                                                data-testid="nameProduct"
                                                                type="text"
                                                                className={styles.main6}
                                                                placeholder={t("Enter product description")}
                                                                data-value=""
                                                                value={description}
                                                                onChange={(e) => setDescription(e.target.value)}
                                                            />
                                                        </div>
                                                        {errors.description &&
                                                            <span className={styles.error}>{errors.description}</span>}
                                                    </div>
                                                    <div data-label="Product Info" data-value="" data-focus="false"
                                                         data-error="false" id="name"
                                                         className={`${styles.input7} ${styles.input}`}
                                                         data-status="false">
                                                        <label className={styles.main4} htmlFor="productInfo">
                                                            {t("Product Info")}
                                                        </label>
                                                        <div className={styles.main5}>
                                                            <input
                                                                autoComplete="off"
                                                                data-testid="productInfo"
                                                                type="text"
                                                                className={styles.main6}
                                                                placeholder={t("Enter product info")}
                                                                data-value=""
                                                                value={productInfo}
                                                                onChange={(e) => setProductInfo(e.target.value)}
                                                            />
                                                        </div>
                                                        {errors.productInfo &&
                                                            <span className={styles.error}>{errors.productInfo}</span>}
                                                    </div>

                                                    <div data-label="Unit" data-value="" data-focus="false"
                                                         data-error="false" id="name"
                                                         className={`${styles.input3} ${styles.input}`}
                                                         data-status="false">
                                                        <label className={styles.main4} htmlFor="9075">{t("Unit")}</label>
                                                        <div className={styles.main5}>
                                                            <input
                                                                autoComplete="off"
                                                                data-testid="nameProduct"
                                                                type="text"
                                                                className={styles.main6}
                                                                placeholder="pcs"
                                                                data-value=""
                                                                value={input3}
                                                                onChange={(e) => setInput3(e.target.value)}
                                                            />
                                                        </div>
                                                        {errors.input3 &&
                                                            <span className={styles.error}>{errors.input3}</span>}
                                                    </div>
                                                    <div data-label="Price" data-value="" data-focus="false"
                                                         data-error="false" id="name"
                                                         className={`${styles.input4} ${styles.input}`}
                                                         data-status="false">
                                                        <label className={styles.main4} htmlFor="9075">
                                                            {t("Price")}
                                                        </label>
                                                        <div className={styles.main5}>
                                                            <input
                                                                autoComplete="off"
                                                                data-testid="nameProduct"
                                                                type="text"
                                                                className={styles.main6}
                                                                placeholder={t("Price")}
                                                                data-value=""
                                                                value={input4}
                                                                onChange={(e) => setInput4(e.target.value)}
                                                            />
                                                        </div>
                                                        {errors.input4 &&
                                                            <span className={styles.error}>{errors.input4}</span>}
                                                    </div>
                                                    <div data-label="Quantity" data-value="" data-focus="false"
                                                         data-error="false" id="name"
                                                         className={`${styles.input5} ${styles.input}`}
                                                         data-status="false">
                                                        <label className={styles.main4} htmlFor="quantity">
                                                            {t("Quantity")}
                                                        </label>
                                                        <div className={styles.main5}>
                                                            <input
                                                                autoComplete="off"
                                                                data-testid="quantity"
                                                                type="text"
                                                                className={styles.main6}
                                                                placeholder={t("Enter quantity")}
                                                                data-value=""
                                                                value={input5}
                                                                onChange={(e) => setInput5(e.target.value)}
                                                            />
                                                        </div>
                                                        {errors.input5 &&
                                                            <span className={styles.error}>{errors.input5}</span>}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={styles.btnBlock}>
                                <button type="submit" className={styles.btnMain} disabled={isSending}>
                                    {t("Submit")}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </>
        </>
    );
});

export default EditProductModal;