import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";

import Box from "@mui/material/Box";
import {
    Button,
    CircularProgress, Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle, Slide,
    styled
} from "@mui/material";
import {t} from "i18next";

import LazyLoad from 'react-lazy-load';
import styles from "./SupportUser.module.scss";
import IconBack from "../../../components/Icons/IconBack";
import {getUserInfo} from "../../../api/getContentsApi/getUserInfo";
import {unUnSupported} from "../../../api/getContentsApi/unUnSupported";
import SupportChat from "../../SupportPage/SupportChat";
import {getSupportChats} from "../../../api/getContentsApi/getSupportChats";
import SupportChatOwner from "../../SupportPage/SupportChatOwner";
import useIsMobile from "../../../helpers/useIsMobile";

const roles = {
    5: "Support",
    4: "Owner",
    3: "Courier",
    2: "Superadminshop",
    1: "User",
}

const StyledDialog = styled(Dialog)`
  .MuiPaper-root {
    border-radius: 20px !important;
  }
`;
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const getRole = (role) => roles[role] || "No role"
const SupportUser = () => {
    const navigate = useNavigate()
    const {id} = useParams();
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [open, setOpen] = React.useState(false);
    const [isOpenModel, setIsOpenModel] = useState("");
    const [chats, setChats] = useState([]);

    useEffect(() => {
        const fetchChats = async () => {
            try {
                setLoading(true);
                const data = await getSupportChats(id); // Передаем query параметр в запрос
                setChats(data);
            } catch (error) {
                console.error("An error occurred while fetching users:", error);
            } finally {
                setLoading(false);
            }
        };
        fetchChats();
    }, []);

    useEffect(() => {
        const fetchUsers = async () => {
            try {
                setLoading(true);
                const data = await getUserInfo(id); // Передаем query параметр в запрос
                setData(data);
            } catch (error) {
                console.error("An error occurred while fetching users:", error);
            } finally {
                setLoading(false);
            }
        };
        fetchUsers();
    }, [id, refresh]); // Add refresh to the dependency array

    const handleBack = () => {
        navigate(-1)
    }

    const handleCloseConfirm = () => {
        setOpen(false);
    };

    const handleUnSupported = async () => {
        await unUnSupported(data?.id)
        setRefresh(prevState => !prevState);
        handleCloseConfirm()
        navigate(-1)
    }
    const handleClose = (e) => {
        if (e) {
            if (e === isOpenModel) {
            } else {
                setIsOpenModel(e)
            }
        } else {
            setIsOpenModel(e)
        }
    }

    const isMobile = useIsMobile();

    return (
        <div style={!isMobile? {padding: "10px 20px", width: "78%"} : {padding: "10px 20px", height: "100vh"}}>
            <div onClick={handleBack}>
                <IconBack size={20} pure={true} disabled fill={"#009de0"} marg={0}/>
                <h3 style={{textAlign: "center", position: "relative", top: "-30px"}}>{t("Support info")}</h3>
            </div>
            <div>
                {loading || !data ?
                    <Box sx={{display: "flex", justifyContent: "center", alignItems: "center", height: "50vh"}}>
                        <CircularProgress/>
                    </Box>
                    : <div>
                        <p>ID: {data?.id ?? ""}</p>
                        <p>{t("Role")}: {getRole(data?.role)}</p>
                        <p>{t("User name")}: {data?.username ?? ""}</p>
                        <p>{t("Email")}: {data.email}</p>
                        <p>{t("Active")}: {data?.is_active ? t("Yes") : t("No")}</p>
                        <p>{t("Is verified")}: {data?.is_verified ? t("Yes") : t("No")}</p>
                        <p>{t("Status")}: {data?.verification_info?.status_display ?? t("No results found")}</p>
                        <div style={{display: "flex", justifyContent: "space-between", marginTop: "40px"}}>
                            <div>
                                <p>{t("User photo")}</p>
                                <LazyLoad height={200} offset={100}>
                                    <img height={200} src={data?.verification_info?.verification_photo_face}
                                         alt={t("User photo  not found")}/>
                                </LazyLoad>

                            </div>
                            <div>
                                <p>{t("Documents photo")}</p>
                                <LazyLoad height={200} offset={100}>
                                    <img height={200} src={data?.verification_info?.verification_pass_id}
                                         alt={t("Documents photo  not found")}/>
                                </LazyLoad>
                            </div>
                        </div>


                    </div>
                }
                <div className={styles.chats}>
                    {loading ?
                        <Box sx={{display: "flex", justifyContent: "center", alignItems: "center", height: "50vh"}}>
                            <CircularProgress/>
                        </Box> :
                        <>
                            {
                                chats.map(chat =>
                                    (
                                        <SupportChatOwner chat={chat}/>
                                    ))
                            }
                        </>
                    }
                </div>
                <div className={styles["button-container"]}>
                    <button className={styles["removeButton"]}
                            onClick={() => {
                                setOpen(true)
                                setIsOpenModel("2")
                            }}>
                        <span
                            className={styles["removeButton--element"]}>
                            {t("Demote")}
                        </span>
                    </button>
                </div>
            </div>
            <StyledDialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>{t("Warning")}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        {isOpenModel === "1" ? t("Are you sure you want to verify the user?") : t("Are you sure you want to delete verification?")}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseConfirm}>{t("Cancel")}</Button>
                    <Button onClick={handleUnSupported}>{t("Confirm")}</Button>
                </DialogActions>
            </StyledDialog>
        </div>
    );
};

export default SupportUser;