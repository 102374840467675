import React, {useEffect, useState} from 'react';
import styles from './ModalSwitchLanguage.module.scss'
import CloseImage from "../static/CloseImage";
import InfoIcon from "../static/InfoIcon";
import EarthIcon from "../static/EarthIcon";
import rootStore from "../../../store/RootStore";
import {observer} from "mobx-react";
const ModalSwitchLanguage = observer(() => {
    const [isOpen, setIsOpen] = useState(false);
    const { languageList, getLanguages, userData, changeLanguage } = rootStore.userStore;

    const [selectedLanguage, setSelectedLanguage] = useState('English');

    useEffect(() => {
        getLanguages()
    }, []);

    const handleLanguageSelect = (language) => {
        setSelectedLanguage(language.name);
        handleCloseModal();
        if(userData.authorized){
            changeLanguage(language.id)
        }
    };

    const handleCloseModal = () => {
        setIsOpen(false);
    };
    const handleClose = (ev) => {
        if (ev.target.id === "modalka"){
            setIsOpen(false);
        }
    }

    return (
        <div>
            {isOpen && (
                <div id="modalka" className={styles["modal-overlay"]} onClick={(ev) => handleClose(ev)}>
                    <div className={styles["modal-content"]}>
                        <div className={styles.closeBtnConteiner}>
                            <button className={styles.buttonBlock} data-backdrop="transparent"
                                    data-size="medium" data-variant="neutral" type="button"
                                    aria-label="Close the dialog" data-test-id="modal-close-button"
                                    onClick={handleCloseModal}>
                                <div className={styles.fakeBtnBlock}></div>
                                <div className={styles.closeImgBtn}>
                                    <svg viewBox="0 0 24 24" className={styles.closeImg}>
                                        <path
                                            d="M2.44 19.44a1.5 1.5 0 002.121 2.121l7.262-7.26a.25.25 0 01.354 0l7.262 7.262a1.5 1.5 0 102.122-2.12l-7.26-7.266a.25.25 0 010-.354l7.262-7.262a1.5 1.5 0 00-2.124-2.12L12.177 9.7a.25.25 0 01-.354 0L4.561 2.44A1.5 1.5 0 002.44 4.56L9.7 11.823a.25.25 0 010 .354L2.44 19.44z"></path>
                                    </svg>
                                </div>
                            </button>
                        </div>
                        <h2 className={styles["header"]}>Choose language</h2>
                        <ul>
                            {languageList.map((element) => (
                                <li className={styles["element"]} key={element.id}>
                                    <InfoIcon/>
                                    <p className={styles["language"]}>{element.name}</p>
                                    <button className={styles["choose"]}
                                            onClick={() => handleLanguageSelect(element)}>Choose
                                    </button>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            )}
            <div className={styles["button"]} onClick={() => setIsOpen(true)}><EarthIcon/> {selectedLanguage}</div>
        </div>
    );
});

export default ModalSwitchLanguage;