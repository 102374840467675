import React, { useState, useEffect } from 'react';
import style from './CompanyName.module.scss'
function CompanyName() {
    const [currentYear, setCurrentYear] = useState(new Date().getFullYear());

    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentYear(new Date().getFullYear());
        }, 86400000);

        return () => clearInterval(intervalId);
    }, []);

    return (
        <div className={style.company}>
            ©️ Wolt 2014–{currentYear}
        </div>
    );
}

export default CompanyName;
